import logo from "./logo.svg";

const CAPTION1 = `dynamic, visual product data securely kept in one spot,`;

const CAPTION2 = "ready to deploy anywhere.";

const MESSAGE = `Dynamic media: product images & product models with ready-to-go web elements you can use to create digital tools that your business needs, that your customers are asking for.  
  API's that help you build better systems that flow with the market and easy to implement.  Say au revoir to templates and the 10x mindset that encumber the process.  Enjoy taking back the control.`;

const ACTION = `v2024 is on the way and it's going to make deploying your product assets simple.`;

const FOOTER = "The Big Picture Machine, Inc. 2024 All Rights Reserved.";

const MAILCHIMP = (
  <div id="mc_embed_shell" className="justify-center">
    <div id="mc_embed_signup">
      <form
        action="https://thebigpicturemachine.us21.list-manage.com/subscribe/post?u=159ec91cb74e03362fa00faa3&amp;id=180223b301&amp;f_id=0031f5e6f0"
        method="post"
        id="mc-embedded-subscribe-form"
        name="mc-embedded-subscribe-form"
        target="_blank"
      >
        <div id="mc_embed_signup_scroll">
          <div className="text-2xl">
            Looking for the Big Picture? Start here...
          </div>
          <div className="text-xs p-2">
            <span className="asterisk">*</span> indicates required
          </div>
          <div className="my-2">
            <input
              type="email"
              name="EMAIL"
              className="text-sm p-2 border-solid border-2 rounded-lg border-zinc-200 cursor-pointer w-96"
              id="mce-EMAIL"
              required={true}
              placeholder="Email*"
            />
          </div>
          <div className="my-2">
            <input
              type="text"
              name="FNAME"
              className="text-sm p-2 border-solid border-2 rounded-lg border-zinc-200 cursor-pointer w-96"
              id="mce-FNAME"
              placeholder="First Name"
            />
          </div>

          <div className="my-2">
            <input
              type="text"
              name="LNAME"
              className="text-sm p-2 border-solid border-2 rounded-lg border-zinc-200 cursor-pointer w-96"
              id="mce-LNAME"
              placeholder="Last Name"
            />
          </div>

          <div id="mce-responses" className="clear">
            <div
              className="response"
              id="mce-error-response"
              style={{ display: "none" }}
            ></div>
            <div
              className="response"
              id="mce-success-response"
              style={{ display: "none" }}
            ></div>
          </div>

          <div aria-hidden="true">
            <text name="b_159ec91cb74e03362fa00faa3_180223b301" tabIndex={-1} />
          </div>

          <div className="my-2">
            <textarea
              className="text-sm p-2 border-solid border-2 rounded-lg border-zinc-200 cursor-pointer w-96"
              name="MMERGE6"
              id="mce-MMERGE6"
              placeholder="Message"
            />
          </div>

          <div className="my-2">
            <input
              className="text-lg p-2 border-solid border-2 rounded-lg border-zinc-200 cursor-pointer w-96"
              type="submit"
              name="subscribe"
              id="mc-embedded-subscribe"
              value="Submit"
            />
          </div>
        </div>
      </form>
    </div>
  </div>
);

function App() {
  return (
    <div className="bg-zinc-100 h-screen">
      <div className="relative w-screen">
        {/* <div className="h-16 flex bg-gradient-to-r from-cyan-500 via-yellow-400 to-orange-600"> */}
        <div className="h-16 flex border-2 border-b-zinc-300">
          <img src={logo} alt="" width="250" className="justify-center px-4" />
        </div>
      </div>

      <div className="content-center flex place-items-center relative mt-16">
        <div className="flex flex-wrap w-full">
          <div className="text-center w-full grid justify-items-center py-4 text-6xl leading-14">
            <div className="text-zinc-800 max-w-3xl">{CAPTION1}</div>
            <div className="text-orange-600 max-w-3xl">{CAPTION2}</div>
          </div>
          <div className="text-zinc-500 text-center w-full grid justify-items-center py-4 text-1xl leading-8">
            <div className="max-w-3xl">{MESSAGE}</div>
          </div>
          <div className="text-zinc-800 text-center w-full grid justify-items-center py-4 text-1xl">
            <div className="max-w-3xl">{ACTION}</div>
          </div>
        </div>
      </div>

      <div className="text-center w-full text-zinc-800">
        <div className="flex w-full justify-center mt-16">{MAILCHIMP}</div>
      </div>
      <div className="absolute bottom-0 w-full">
        <div className="text-center text-xs p-4">{FOOTER}</div>
      </div>
    </div>
  );
}

export default App;
